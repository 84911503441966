import { Expose, Transform, Type } from 'class-transformer';
import parse from 'date-fns/parse';
import type { Address } from './Address';
import { Doctor } from './Doctor';
import { Order } from './Order';
import type { OrderItem } from './OrderItem';

export class OrderDetails extends Order {
  @Transform(({ value }) => parse(value, 'dd.MM.yyyy HH:mm:ss', new Date()), { toClassOnly: true })
  readonly create_date!: Date;

  readonly delivery_address!: Address;

  @Transform(({ value }) => parse(value, 'dd.MM.yyyy HH:mm:ss', new Date()), { toClassOnly: true })
  readonly delivery_date!: Date;

  readonly receipt_id!: number;

  @Type(() => Doctor)
  readonly doctor!: Doctor;

  @Expose({
    name: 'order_items',
  })
  readonly items: OrderItem[] = [];
}
