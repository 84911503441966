import { useQuery } from '@tanstack/react-query';
import plural from 'plural-ru';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { OrderApi, ReceiptApi } from '../../api';
import { RoutePath } from '../../config';
import { formatDateRu, formatMoney } from '../../utils';
import { Modal, OrderItemRow } from '../molecules';

interface Props {
  onClose(): void;
  orderId?: number;
}

export const OrderDetailsModal: FC<Props> = ({ onClose, orderId }) => {
  const { data: orderDetails } = useQuery({
    queryKey: [OrderApi.KEY, 'getById', orderId],
    queryFn: () => OrderApi.getById(orderId!, true),
    enabled: orderId !== undefined,
  });
  const { data: receipt } = useQuery({
    queryKey: [ReceiptApi.KEY, 'getById', orderDetails?.receipt_id],
    queryFn: () => ReceiptApi.getById(orderDetails?.receipt_id!),
    enabled: orderDetails !== undefined,
  });
  console.log(receipt)
  return (
    <Modal isOpen={orderId !== undefined && orderDetails !== undefined} onClose={onClose}>
      {orderDetails !== undefined && (
        <div className="py-[16px] px-[24px]">
          <header className="mb-5">
            <h3 className="mb-[6px] text-[24px] leading-normal font-bold">
              {orderDetails.status.value} {formatDateRu(orderDetails.status_date, 'dd MMMM, yyyy')}
            </h3>
            <Link
              className="text-brand-5 border-b border-brand-5"
              to={{
                pathname: RoutePath.ClientCalendar,
                // search: receipt ? `?startDate=${formatDateRu(receipt.start_date, 'dd MMMM, yyyy')}` : '',
              }}
            >
              Календарь заказа
            </Link>
          </header>
          <table className="table">
            <thead>
              <tr>
                <th className="w-[24px] text-left">&nbsp;</th>
                <th>Лекарства</th>
                <th className="text-right">
                  Сформировал(а) заказ:{' '}
                  <span className="text-brand-5 border-b border-brand-5">{orderDetails.doctor.fullName}</span>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {orderDetails.items.map((item, index) => (
                <OrderItemRow key={item.id} item={item} index={index} />
              ))}
              <tr>
                <td />
                <td>
                  Итого за {orderDetails.medicine_count}{' '}
                  {plural(orderDetails.medicine_count, 'лекарство', 'лекарства', 'лекарств')}
                </td>
                <td className="text-right text-[20px]">{formatMoney(orderDetails.price)}</td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  );
};
