import classNames from 'classnames';
import formatRelative from 'date-fns/formatRelative';
import ruLocale from 'date-fns/locale/ru';
import plural from 'plural-ru';
import type { FC } from 'react';
import { OrderDetails } from '../../model';

interface Props {
  active?: boolean;
  order: OrderDetails;
}

export const ClientOrderCard: FC<Props> = ({ active = false, order }) => {
  return (
    <div>
      <header
        className={classNames('flex items-center justify-between py-[16px] px-[24px] rounded-t-lg', {
          'bg-brand-1': !active,
          'bg-brand-5': active,
        })}
      >
        <h2
          className={classNames('text-[20px] leading-normal font-bold', {
            'text-white': !active,
            'text-brand-1': active,
          })}
        >
          {active ? 'Действующий заказ' : 'Следующий заказ'}
        </h2>
        <span className={active ? 'text-brand-1/30' : 'text-white/50'}>№{order.id}</span>
      </header>
      <div className="p-2 bg-white rounded-b-lg text-[14px]">
        <div className="grid grid-cols-2 pb-2 border-b border-brand-1/10">
          <div>
            <span className="block mb-[6px]">
              <span className="text-brand-1/50">Доставка </span>
              {formatRelative(order.delivery_date, new Date(), {
                locale: ruLocale,
              })}
            </span>
            <span className="block text-brand-1/50">Сформировал(а) заказ:</span>
            <span className="text-brand-5 border-b border-brand-5">{order.doctor.fullName}</span>
          </div>
          <div>
            <span className="mb-[6px] block text-brand-1/50">Адрес доставки:</span>
            {order.delivery_address.address_text}
          </div>
        </div>
        <footer className="mt-2">
          <span className="text-brand-1/50">В этом заказе</span>{' '}
          <span className="text-brand-5 border-b border-brand-5">
            {order.medicine_count} {plural(order.medicine_count, 'медикамент', 'медикамента', 'медикаментов')}
          </span>
        </footer>
      </div>
    </div>
  );
};
