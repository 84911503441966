import { FC, useCallback } from 'react';
import { ReactComponent as SuccessIcon } from '../../assets/images/success.svg';
import { Modal } from '../molecules';
import { OrderApi } from '../../api';
import { useMutation } from '@tanstack/react-query';

interface Props {
  onClose(): void;
  receiptId?: number;
}

export const ConfirmOrderModal: FC<Props> = ({ onClose, receiptId }) => {
  const { mutate } = useMutation({
    mutationFn: OrderApi.confirm,
    onSuccess() {
      onClose();
    },
  });

  const handleConfirmClick = useCallback(() => {
    if (receiptId !== undefined) {
      mutate(receiptId);
    }
  }, [receiptId, mutate]);

  return (
    <Modal isOpen={Boolean(receiptId)} onClose={onClose}>
      <div className="p-[72px] text-center">
        <span className="flex justify-center">
          <SuccessIcon />
        </span>
        <h2 className="mt-[24px] text-[24px] leading-none">Заказ успешно собран!</h2>
        <span className="mt-[36px] block">Чтобы отправить клиенту, нажмите “подвердить” </span>
        <footer className="mt-[36px] flex justify-center gap-[24px]">
          <button className="button button--outlined px-[40px]" onClick={onClose} type="button">
            Отменить
          </button>
          <button className="button button--primary px-[40px]" onClick={handleConfirmClick} type="button">
            Подтвердить
          </button>
        </footer>
      </div>
    </Modal>
  );
};
