import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AuthRoute, ClientLoginFormFirstStep, ClientLoginFormSecondStep } from '../components';
import type { ClientLoginFormFields } from '../hooks';
import { ClientAuthLayout } from '../layouts';

export const ClientLoginPage: FC = () => {
  const [step, setStep] = useState(0);
  const form = useForm<ClientLoginFormFields>({
    defaultValues: {
      code: '',
      phoneNumber: '',
    },
  });

  return (
    <AuthRoute>
      <ClientAuthLayout>
        <FormProvider {...form}>
          {step === 0 && <ClientLoginFormFirstStep onSuccess={() => setStep(1)} />}
          {step === 1 && <ClientLoginFormSecondStep />}
        </FormProvider>
      </ClientAuthLayout>
    </AuthRoute>
  );
};
