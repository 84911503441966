import classNames from 'classnames';
import { FC, useState } from 'react';
import { ClientOrders, ClientPayments, PrivateRoute } from '../components';
import { ClientInnerPageLayout } from '../layouts';

export const ClientOrderHistoryPage: FC = () => {
  const [tab, setTab] = useState<'orders' | 'payments'>('orders');

  return (
    <PrivateRoute>
      <ClientInnerPageLayout>
        <div className="mb-[36px] flex gap-[8px]">
          <button
            className={classNames('py-[4px] px-[16px] rounded-sm leading-normal border border-white transition-all', {
              'bg-white': tab === 'orders',
            })}
            onClick={() => setTab('orders')}
            type="button"
          >
            Заказы
          </button>
          <button
            className={classNames('py-[4px] px-[16px] rounded-sm leading-normal border border-white transition-all', {
              'bg-white': tab === 'payments',
            })}
            onClick={() => setTab('payments')}
            type="button"
          >
            Платежи
          </button>
        </div>

        {tab === 'orders' && <ClientOrders />}
        {tab === 'payments' && <ClientPayments />}
      </ClientInnerPageLayout>
    </PrivateRoute>
  );
};
