import { useQuery } from '@tanstack/react-query';
import type { FC } from 'react';
import { PaymentApi } from '../../api';
import format from 'date-fns/format';
import { formatMoney } from '../../utils';

export const ClientPayments: FC = () => {
  const { data: allPayments = [] } = useQuery({
    queryKey: [PaymentApi.KEY, 'getAll'],
    queryFn: PaymentApi.getAll,
  });

  return (
    <div className="bg-white rounded-lg overflow-hidden text-brand-1">
      <header className="mb-2 py-[16px] px-[24px] bg-brand-6 rounded-t-lg">
        <h2 className="text-[20px] leading-normal font-bold">История платежей</h2>
      </header>
      <table className="table">
        <thead>
          <tr>
            <th className="w-[24px] border-none">&nbsp;</th>
            <th className="text-left">Номер платежа</th>
            <th className="text-left">Номер заказа</th>
            <th className="text-left">Дата</th>
            <th className="text-left">Статус</th>
            <th className="text-left">Размер платежа</th>
            <th className="w-[24px] border-none">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {allPayments.map((payment) => (
            <tr key={payment.id}>
              <td className="border-none" />
              <td>
                <span className="border-b border-brand-1">№{payment.id}</span>
              </td>
              <td>
                <span className="border-b border-brand-1">№{payment.order_id}</span>
              </td>
              <td>{format(payment.process_date, 'dd.MM.yyyy')}</td>
              <td>{payment.status.value}</td>
              <td>{formatMoney(payment.amount)}</td>
              <td className="border-none" />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
