import axios from 'axios';
import { ApiResponse } from '../model';

interface DoctorAuthParams {
  hospital_id: number;
  password: string;
  username: string;
}

export class AuthApi {
  static async authorizeDoctor(params: DoctorAuthParams): Promise<any> {
    const { data } = await axios.post<ApiResponse>('/api/v1/auth/doctor/login', params);

    return data;
  }

  static async sendCode(phone: string): Promise<ApiResponse> {
    const { data } = await axios.post<ApiResponse>('/api/v1/auth/send_code', { phone });

    return data;
  }

  static async validateCode(phone: string, code: string): Promise<ApiResponse> {
    const { data } = await axios.post<ApiResponse>('/api/v1/auth/validate_code', { code, phone });

    return data;
  }

  static async logOut(): Promise<ApiResponse> {
    const { data } = await axios.post<ApiResponse>('/api/v1/auth/logout');

    return data;
  }
}
