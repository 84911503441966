import { createContext } from 'react';
import { noop } from '../utils';

export interface AuthContextValue {
  isAuthorized: boolean;
  signIn(isClient: boolean): void;
  signOut(): void;
}

export const AuthContext = createContext<AuthContextValue>({
  isAuthorized: false,
  signIn: noop,
  signOut: noop,
});
