import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import {FC, useCallback, useState} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CommonApi, MedicineApi } from '../../api';
import { ReactComponent as ForwardIcon } from '../../assets/icons/forward.svg';
import type { Medicine, ReferenceItem } from '../../model';
import styles from './DoctorAddMedicineToOrderForm.module.css';
import { Select } from '../atoms';
import { MedicineAutocomplete } from '../molecules';
import { formatMedicinePlural } from '../../utils';
import {log} from "node:util";


interface Props {
  onAdd(data: OrderMedicine): void;
}

export interface OrderMedicine {
  count: number;
  eating: ReferenceItem;
  frequency: ReferenceItem;
  medicine: Medicine;
  time: ReferenceItem;
  timeEating: [{
    medicineId: number;
    timeId: number;
    value: any;
    str: string;
  }];
}

interface FormFields {
  count: number;
  eatingId: string;
  frequencyId: number;
  medicineId: number;
  timeId: string;
  timeEating: {
    id: number
  } | {}
}

interface FormGetTime{
  idTime: number;
  value: string;
}

type TimeEatingState = {
  [key: string]: {
    value: string,
    isClicked: boolean
  };
};

type GetTime = {
  timeId: number;
  value: string;
  errors: any;
  register: any;
}

function GetTimeCount({timeId, value, errors, register}: GetTime){
  return (
    <div>
      { timeId  !== null && (
        <div>
          <span className="block mb-[12px] text-[14px]">Количество {value}</span>
          <input
            {...register(`timeEating[${timeId}]`, {
              required: true,
              valueAsNumber: true,
              min: 1,
            })}
            className={classNames('mb-[12px] input w-[130px]', errors.count && 'input--invalid')}
            step={1}
            defaultValue={1}
            type="number"
          />
        </div>
        )}
    </div>
  )
}

export const DoctorAddMedicineToOrderForm: FC<Props> = ({onAdd}) => {
  //  @ts-ignore
  const [queryParams, setQueryParams] = useState(['T', 'T'])
  const [timeEating, setTimeEating] = useState<TimeEatingState>({})
  const [timeListEating, setTimeListEating] = useState([])
  const {data: medicineList = []} = useQuery({
    queryKey: [MedicineApi.KEY, 'getAll', ...queryParams],
    queryFn: () => MedicineApi.getAll(queryParams[0], queryParams[1]),
  });
  const { data: timeList = [] } = useQuery({
    queryKey: [CommonApi.KEY, 'getReference', 202],
    queryFn: () => CommonApi.getReference(202),
  });
  const { data: frequencyList = [] } = useQuery({
    queryKey: [CommonApi.KEY, 'getReference', 200],
    queryFn: () => CommonApi.getReference(200),
  });
  const { data: eatingList = [] } = useQuery({
    queryKey: [CommonApi.KEY, 'getReference', 201],
    queryFn: () => CommonApi.getReference(201),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    resetField,
    reset
  } = useForm<FormFields>();


  const getStateIsClicked = (idTime: number) =>{
    return timeEating[idTime] ? !timeEating[idTime].isClicked : false
  }

  const  onClick = ({idTime, value}: FormGetTime) => {
    setTimeEating(prevState => ({
      ...prevState,
      [idTime]: {
        value, isClicked: getStateIsClicked(idTime)
      }
    }))
    if (!getStateIsClicked(idTime)){
      //  @ts-ignore
      setTimeListEating(prevState => [
        ...prevState,
        { idTime: idTime, value: value }
      ]);
    } else {
      //  @ts-ignore
      const updateItem = timeListEating.filter((item) => item.idTime !== idTime)
      setTimeListEating(updateItem)
      //  @ts-ignore
      resetField(`timeEating[${idTime}]`)

    }
  }

  const resetForm = () => {
    reset()
    timeListEating.forEach((item) => {
      //  @ts-ignore
      resetField(`timeEating[${item.idTime}]`)
    })
    setTimeListEating([])
  }

  //  @ts-ignore
  function GetDataForEating(eatingList, medicine){
    return timeList.map((item)=>{
      if (eatingList[item.id])
       return {
        medicineId: medicine.id,
        timeId: item.id,
        value: eatingList[item.id],
        str: `${item.value} ${eatingList[item.id]} ${formatMedicinePlural(eatingList[item.id])}`
       }
      return
    })
  }

  const onSubmit: SubmitHandler<FormFields> = useCallback(
    (data) => {
      console.log(data)
      const dataAdd = {
        count: data.count,
        eating: eatingList.find((item) => item.id === Number(data.eatingId))!,
        frequency: frequencyList.find((item) => item.id === data.frequencyId)!,
        medicine: medicineList.find((item) => item.id === data.medicineId)!,
        time: timeList.find((item) => item.id === Number(data.timeId))!,
        timeEating: GetDataForEating(data.timeEating, medicineList.find((item) => item.id === data.medicineId)!)
      }
      //  @ts-ignore
      onAdd(dataAdd);
      console.log(timeListEating)
      resetForm();
    },
    [frequencyList, eatingList, medicineList, onAdd, timeList],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-[24px]">
        <Controller
            control={control}
            name="medicineId"
            rules={{
              required: true,
            }}
            render={({field, fieldState}) => (
                <MedicineAutocomplete
                    className="mb-[12px]"
                    error={fieldState.error}
                    onChange={field.onChange}
                    value={field.value}
                    setQueryParams={setQueryParams}
                />
            )}
        />

        <Controller
            control={control}
            name="frequencyId"
            rules={{
              required: true,
            }}
            render={({field, fieldState}) => {
              return (
                  <Select
                      className="mb-[24px]"
                      error={fieldState.error}
                      onChange={field.onChange}
                      options={frequencyList.map((frequency) => ({
                        label: frequency.value,
                        value: frequency.id,
                      }))}
                      placeholder="Частота приема"
                      value={field.value}
                  />
              );
            }}
        />
        <div className="mb-[24px]">
          <span className="block mb-[12px] text-[14px]">Время приема</span>
          <div className="flex gap-[12px]">
            {timeList.map((time) => (
                <label className={styles.radio} key={time.id}>
                  <input
                      className="hidden"
                      type="radio"
                      value={time.id}
                      checked={getStateIsClicked(time.id)}
                      onClick={() => onClick({idTime: time.id, value: time.value})}
                  />
                  <span className="whitespace-nowrap leading-normal">{time.value}</span>
                </label>
            ))}
          </div>
        </div>
        <div className="flex gap-[12px]">
          <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', flexWrap: 'wrap'}}>
            {timeListEating && timeListEating?.map((time: any) => (
                <div style={{display: "flex"}}>
                  <GetTimeCount timeId={time.idTime} value={time.value} errors={errors} register={register}/>
                </div>
            ))}
          </div>
          {/*<input*/}
          {/*  {...register('count', {*/}
          {/*    required: true,*/}
          {/*    valueAsNumber: true,*/}
          {/*    min: 1,*/}
          {/*  })}*/}
          {/*  className={classNames('mb-[12px] input w-[130px]', errors.count && 'input--invalid')}*/}
          {/*  step={1}*/}
          {/*  defaultValue={1}*/}
          {/*  type="number"*/}
          {/*/>*/}
          {/*<div className="flex flex-wrap gap-[6px]">*/}
          {/*  {eatingList.map((eating) => (*/}
          {/*    <label className={styles.radio} key={eating.id}>*/}
          {/*      <input*/}
          {/*        {...register('eatingId', {*/}
          {/*          required: true,*/}
          {/*        })}*/}
          {/*        className="hidden"*/}
          {/*        type="radio"*/}
          {/*        value={eating.id}*/}
          {/*      />*/}
          {/*      <span className="whitespace-nowrap leading-normal">*/}
          {/*        {eating.value}&nbsp;{formatMedicinePlural(Number(eating.value))}*/}
          {/*      </span>*/}
          {/*    </label>*/}
          {/*  ))}*/}
          {/*</div>*/}
        </div>
      </div>
      <footer className="p-[24px]">
        <button
            className="button button--primary w-full flex items-center justify-between px-2 text-left"
            type="submit"
        >
          Добавить лекарство
          <ForwardIcon/>
        </button>
      </footer>
    </form>
  );
};
