import type { FC, PropsWithChildren } from 'react';
import { Logo } from '../components';

export const DoctorAuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="min-h-screen bg-brand-3 grid grid-rows-[62px_1fr]">
      <header className="flex items-end justify-center">
        <Logo />
      </header>
      <div className="flex items-center justfiy-center mt-[-62px]">{children}</div>
    </div>
  );
};
