export enum RoutePath {
  ClientCalendar = '/client/calendar',
  ClientLogin = '/client/login',
  ClientMedicine = '/client/medicine',
  ClientOrderHistory = '/client/order-history',
  ClientSettings = '/client/settings',
  DoctorCreateOrder = '/doctor/create-order',
  DoctorCreatePatient = '/doctor/patients/create',
  DoctorLogin = '/doctor/login',
  DoctorMedicine = '/doctor/medicine',
  DoctorOrderHistory = '/doctor/order-history',
  DoctorOrderHistoryItem = '/doctor/order-history/:orderId',
  DoctorPatientDetails = '/doctor/patients/:patientId',
  DoctorPatientOrderDetails = '/doctor/patients/:patientId/orders/:orderId',
  DoctorPatients = '/doctor/patients',
}
