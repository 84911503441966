import { Transform } from 'class-transformer';
import parse from 'date-fns/parse';
import { EnumEntity } from './EnumEntity';

export class Order {
  readonly id!: number;
  readonly medicine_count!: number;
  readonly price!: number;
  readonly status!: EnumEntity;

  @Transform(({ value }) => parse(value, 'dd.MM.yyyy HH:mm:ss', new Date()), { toClassOnly: true })
  readonly status_date!: Date;
}
