import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import format from 'date-fns/format';
import { FC, useState } from 'react';
import { Link, RouteProps, generatePath, useParams } from 'react-router-dom';
import { OrderApi, PeopleApi } from '../api';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { DoctorOrderDetails, PrivateRoute } from '../components';
import { RoutePath } from '../config';
import { DoctorInnerPageLayout } from '../layouts';
import { formatMoney } from '../utils';
import styles from './DoctorPatientDetailsPage.module.css';

export const DoctorPatientDetailsPage: FC<RouteProps> = () => {
  const [tab, setTab] = useState<'history' | 'active'>('history');
  const { patientId } = useParams<{ patientId: string }>();
  const { data: patient } = useQuery({
    enabled: !!patientId,
    queryFn: () => PeopleApi.getPatientDetails(patientId!),
    queryKey: [PeopleApi.KEY, 'getPatientDetails', patientId],
  });
  const { data: orderHistory = [] } = useQuery({
    enabled: !!patientId,
    queryFn: () => OrderApi.getHistoryByPatientId(patientId!),
    queryKey: [OrderApi.KEY, 'getHistoryByPatientId', patientId],
  });

  if (!patientId || !patient) {
    return (
      <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
        <DoctorInnerPageLayout />
      </PrivateRoute>
    );
  }

  return (
    <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
      <DoctorInnerPageLayout>
        <div className="bg-brand-6 rounded-lg">
          <header className="p-[24px]">
            <Link className="flex items-center gap-[6px] text-[20px] font-bold" to={RoutePath.DoctorPatients}>
              <BackIcon />
              Вернуться назад
            </Link>
          </header>

          <div className="bg-white rounded-lg">
            <div className="flex gap-[24px] px-[24px] py-[36px]">
              <div className="flex items-center justify-center w-[120px] h-[120px] bg-brand-7 rounded-md text-[36px]">
                {patient.initials}
              </div>

              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="mb-[6px] text-[24px]">{patient.fullName}</h1>
                  <span className="block">{format(patient.birthdate, 'dd.MM.yyyy')}</span>
                </div>
                <div className="leading-[24px]">
                  {patient.phone && (
                    <div className="flex gap-[12px]">
                      <span className="flex-1 text-brand-1/50">Телефон</span>
                      <span>{patient.phone}</span>
                    </div>
                  )}
                  {patient.email && (
                    <div className="flex gap-[12px]">
                      <span className="flex-1 text-brand-1/50">Электронная почта</span>
                      <span>{patient.email}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-brand-6 rounded-lg">
              <header className="flex gap-[36px] px-[24px]">
                <button
                  className={classNames(styles.tab, tab === 'history' && styles.active)}
                  onClick={() => setTab('history')}
                  type="button"
                >
                  История заказов
                </button>
                {patient.active_order && (
                  <button
                    className={classNames(styles.tab, tab === 'active' && styles.active)}
                    onClick={() => setTab('active')}
                    type="button"
                  >
                    Действующий заказ
                  </button>
                )}
              </header>
              {tab === 'history' && (
                <div className="px-[24px] pt-[24px] pb-[12px] bg-white rounded-lg">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-left">Номер заказа</th>
                        <th className="text-left">Доставлен</th>
                        <th className="text-left">Лекарства</th>
                        <th className="text-left">Цена</th>
                        <th className="text-left">Статус</th>
                        <th className="text-left">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderHistory.length === 0 && (
                        <tr>
                          <td colSpan={7} className="text-center">
                            Ничего не найдено
                          </td>
                        </tr>
                      )}
                      {orderHistory.map((order) => (
                        <tr className="leading-[24px]" key={order.id}>
                          <td>№{order.id}</td>
                          <td>{format(order.status_date, 'dd.MM.yyyy')}</td>
                          <td className="underline">{order.medicine_count}</td>
                          <td>{formatMoney(order.price)}</td>
                          <td>{order.status.value}</td>
                          <td className="underline text-brand-5">
                            <Link
                              to={generatePath(RoutePath.DoctorPatientOrderDetails, {
                                orderId: order.id.toString(),
                                patientId,
                              })}
                            >
                              Детали
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {tab === 'active' && patient.active_order && <DoctorOrderDetails orderId={patient.active_order.id} />}
            </div>
          </div>
        </div>
      </DoctorInnerPageLayout>
    </PrivateRoute>
  );
};
