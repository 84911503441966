import type { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import { ReactComponent as ChatIcon } from '../assets/icons/chat.svg';
import { ReactComponent as HistoryIcon } from '../assets/icons/history.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg';
import { ReactComponent as MedicineIcon } from '../assets/icons/medicine.svg';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { Logo } from '../components';
import { RoutePath } from '../config';
import { useAuthContext, useProfile } from '../hooks';
import styles from './ClientInnerPageLayout.module.css';

export const ClientInnerPageLayout: FC<PropsWithChildren> = ({ children }) => {
  const { signOut } = useAuthContext();
  const profile = useProfile(true);

  return (
    <div className="grid grid-cols-[200px_1fr] p-[12px] gap-2 min-w-[1280px]">
      <aside className="flex flex-col gap-[22px] pl-[8px]">
        <header className="mt-[28px] ml-[16px]">
          <Logo />
        </header>
        <div className="flex-1">
          <div className="grid gap-[4px]">
            <NavLink className={styles.item} to={RoutePath.ClientMedicine}>
              <MedicineIcon />
              Ваши лекарства
            </NavLink>
            <NavLink className={styles.item} to={RoutePath.ClientCalendar}>
              <CalendarIcon />
              Календарь
            </NavLink>
            <NavLink className={styles.item} to={RoutePath.ClientOrderHistory}>
              <HistoryIcon />
              История заказов
            </NavLink>
          </div>
          <hr className="my-[13px] border-brand-1/20" />
          <div className="grid gap-[4px]">
            <button className={styles.item} type="button">
              <ChatIcon />
              Чат
            </button>
            <button className={styles.item} type="button">
              <PhoneIcon />
              Звонок
            </button>
          </div>
        </div>
        <footer className="pb-2">
          <span className={styles.item}>
            <img alt="Avatar" height="40" width="40" src={require('../assets/images/avatar.png')} />
            {profile?.name}
          </span>
          <hr className="my-[13px] border-brand-1/20" />
          <div className="grid gap-[4px]">
            <NavLink className={styles.item} to={RoutePath.ClientSettings}>
              <SettingsIcon />
              Настройки
            </NavLink>
            <button
              className={styles.item}
              onClick={() => {
                signOut();
              }}
              type="button"
            >
              <LogoutIcon />
              Выйти
            </button>
          </div>
        </footer>
      </aside>
      <div className="p-[36px] min-h-[calc(100vh-24px)] bg-brand-3 rounded-lg">{children}</div>
    </div>
  );
};
