import { useQuery } from '@tanstack/react-query';
import { PeopleApi } from '../api';
import { User } from '../model';
import { useAuthContext } from './useAuthContext';

export function useProfile(isClient = false): User | undefined {
  const { signOut } = useAuthContext();

  const { data: profile } = useQuery({
    queryKey: [PeopleApi.KEY, 'me', isClient],
    queryFn: () => PeopleApi.me(isClient),
    onError() {
      signOut();
    },
  });

  return profile;
}
