import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import { ApiResponse, CollectionApiResponse, Patient, User } from '../model';

export interface UserDTO {
  address_text: string;
  midname: string;
  name: string;
  surname: string;
}

export interface PatientDTO {
  birthdate: string;
  code: string;
  gender_id: number;
  midname: string;
  name: string;
  surname: string;
  username: string; // phone
}

export class PeopleApi {
  static readonly KEY = 'people';

  static async me(isClient: boolean): Promise<User> {
    const { data } = await axios.get<ApiResponse<User>>(isClient ? '/api/v1/client/people' : '/api/v1/people/me');

    return plainToInstance(User, data.data);
  }

  static async getPatients(search?: string): Promise<Patient[]> {
    const { data } = await axios.get<CollectionApiResponse<Patient>>('/api/v1/people', {
      params: {
        search,
      },
    });

    return plainToInstance(Patient, data.items);
  }

  static async getPatientDetails(id: string): Promise<Patient> {
    const { data } = await axios.get<ApiResponse<Patient>>(`/api/v1/people/${id}`);

    return plainToInstance(Patient, data.data);
  }

  static async updateMe(body: UserDTO): Promise<User> {
    const { data } = await axios.put<ApiResponse<User>>('/api/v1/client/people', body);

    return plainToInstance(User, data.data);
  }

  static async createPatient(body: PatientDTO): Promise<any> {
    const { data } = await axios.post<ApiResponse<any>>('/api/v1/doctor/patient', body);

    return data;
  }

  static async sendCodeToPatient(phone: string): Promise<ApiResponse> {
    const { data } = await axios.post<ApiResponse>('/api/v1/doctor/send_code', { phone });

    return data;
  }
}
