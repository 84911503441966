import type { FC } from 'react';
import { Medicine } from '../../model';
import { formatMoney } from '../../utils';

interface Props {
  medicine: Medicine;
}

export const MedicineDetails: FC<Props> = ({ medicine }) => {
  return (
    <div className="py-[16px] px-[24px]">
      <h2 className="font-bold text-[24px] leading-[36px]">{medicine.name}</h2>
      <div className="grid grid-cols-2 items-center">
        <img alt={medicine.name} src={medicine.picture.image} />
        <div className="grid gap-1">
          <span className="block">
            <strong>Описание:</strong>&nbsp;{medicine.description}
          </span>
          <span className="block">
            <strong>Производитель:</strong>&nbsp;{medicine.manufacturer}
          </span>
          <span className="block">
            <strong>Форма выпуска:</strong>&nbsp;{medicine.form_type.value}&nbsp;массой&nbsp;{medicine.dosage}&nbsp;
            {medicine.dosage_type.value}
          </span>
          <span className="block">
            <strong>Цена:</strong>&nbsp;{formatMoney(medicine.medicine_price)}
          </span>
          <span className="block">
            <strong>Состав:</strong>&nbsp;
            {medicine.components.map((component) => component.component.name).join(', ')}
          </span>
        </div>
      </div>
    </div>
  );
};
