import axios from 'axios';
import type { Medicine } from '../model';
import type { CollectionApiResponse } from '../model/CollectionApiResponse';

export class MedicineApi {
  static readonly KEY = 'medicine';

  static async getAll(starts_with: string | null, search?: string, is_starts_with= false): Promise<Medicine[]> {
    const { data } = await axios.get<CollectionApiResponse<Medicine>>('/api/v1/medicine', {
      params: {
        search,
        starts_with,
        is_starts_with
      },
    });

    return data.items;
  }
}
