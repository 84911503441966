import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';
import { FC, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { PeopleApi } from '../api';
import { PrivateRoute } from '../components';
import { RoutePath } from '../config';
import { DoctorInnerPageLayout } from '../layouts';

export const DoctorPatientsPage: FC = () => {
  const [searchText, setSearchText] = useState('');
  const { data: patients = [] } = useQuery({
    keepPreviousData: true,
    queryFn: () => PeopleApi.getPatients(searchText),
    queryKey: [PeopleApi.KEY, 'getPatients', searchText],
  });

  return (
    <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
      <DoctorInnerPageLayout>
        <div className="bg-brand-6 rounded-lg">
          <header className="flex gap-x-[24px] p-[24px]">
            <input
              className="input input--search"
              onChange={(event) => setSearchText(event.target.value)}
              placeholder="Введите ФИО клиента"
              type="text"
            />
            <Link className="button button--primary px-[24px]" to={RoutePath.DoctorCreatePatient}>
              Добавить&nbsp;клиента
            </Link>
          </header>
          <div className="px-[24px] pt-[24px] pb-[12px] bg-white rounded-lg">
            <table className="table">
              <thead>
                <tr>
                  <th className="w-[60px]" />
                  <th className="text-left pl-[24px]">Клиент</th>
                  <th className="text-left">Контакты</th>
                  <th className="text-left">Текущий заказ</th>
                  <th className="text-left">Заказы</th>
                </tr>
              </thead>
              <tbody>
                {patients.map((patient) => {
                  const pathToPatientDetails = generatePath(RoutePath.DoctorPatientDetails, {
                    patientId: patient.id.toString(),
                  });

                  return (
                    <tr className="leading-[24px]" key={patient.id}>
                      <td>
                        <div className="flex items-center justify-center w-[60px] h-[60px] bg-brand-7 rounded-md text-[22px]">
                          {patient.initials}
                        </div>
                      </td>
                      <td className="pl-[24px]">
                        <Link className="block underline" to={pathToPatientDetails}>
                          {patient.fullName}
                        </Link>
                        <span className="text-brand-1/50">{format(patient.birthdate, 'dd.MM.yyyy')}</span>
                      </td>
                      <td>
                        {patient.phone}
                        {patient.email && <span className="block underline text-[14px]">{patient.email}</span>}
                      </td>
                      <td className="">
                        {patient.active_order ? `№ ${patient.active_order?.id}` : `нет`}
                      </td>
                      <td className="underline text-brand-5">
                        <Link to={pathToPatientDetails}>История заказов</Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </DoctorInnerPageLayout>
    </PrivateRoute>
  );
};
