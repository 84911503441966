import { Combobox, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { PeopleApi } from '../../api';

interface Props {
  className?: string;
  error?: FieldError;
  onChange(newValue: number): void;
  value: number;
}

export const PatientAutocomplete: FC<Props> = ({ className, error, onChange, value }) => {
  const [query, setQuery] = useState('');
  const {
    data: patients = [],
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: [PeopleApi.KEY, 'getPatients', query],
    queryFn: () => PeopleApi.getPatients(query),
  });

  return (
    <Combobox value={value} onChange={onChange}>
      <div className={classNames('relative', className)}>
        <Combobox.Input
          className={classNames('input input--block bg-white text-left', {
            'input--invalid': Boolean(error),
          })}
          displayValue={(patientId) => patients.find((patient) => patient.id === patientId)?.fullName ?? ''}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Выбор пациента"
        />
        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-[300px] w-full overflow-auto rounded-md bg-white shadow-lg border border-brand-2">
            {isLoading && (
              <Combobox.Option disabled className="relative cursor-pointer select-none p-2 hover:bg-brand-6" value="">
                Загрузка...
              </Combobox.Option>
            )}
            {isSuccess && patients.length === 0 && (
              <Combobox.Option disabled className="relative cursor-pointer select-none p-2 hover:bg-brand-6" value="">
                Ничего не найдено
              </Combobox.Option>
            )}
            {patients.map((patient) => (
              <Combobox.Option
                key={patient.id}
                className="relative cursor-pointer select-none p-2 hover:bg-brand-6"
                value={patient.id}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={classNames('block truncate', {
                        'font-bold': selected,
                      })}
                    >
                      {patient.fullName}
                    </span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
