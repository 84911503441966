import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { AuthApi } from '../../api';
import { ClientLoginFormFields, useClientLoginForm } from '../../hooks';
import { normalizePhoneNumber } from '../../utils';

interface Props {
  onSuccess(): void;
}

export const ClientLoginFormFirstStep: FC<Props> = ({ onSuccess }) => {
  const { control, handleSubmit } = useClientLoginForm();

  const onSubmit: SubmitHandler<ClientLoginFormFields> = useCallback(
    async ({ phoneNumber }) => {
      await AuthApi.sendCode(normalizePhoneNumber(phoneNumber));
      onSuccess();
    },
    [onSuccess],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mx-auto w-full max-w-[360px]">
      <h1 className="mb-[36px] text-center text-[30px] leading-[36px] font-medium">Личный кабинет</h1>
      <Controller
        control={control}
        name="phoneNumber"
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => {
          return (
            <InputMask
              {...field}
              alwaysShowMask
              required
              className={classNames('mb-[16px] input', fieldState.invalid && 'input--invalid')}
              mask="+7 999 999 99 99"
              maskPlaceholder={null}
              type="tel"
              value={field.value ?? ''}
            />
          );
        }}
      />
      <button className="button button--block button--primary" type="submit">
        Войти
      </button>
    </form>
  );
};
