import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import { ApiResponse, Order, OrderDetails } from '../model';
import { CollectionApiResponse } from './../model/CollectionApiResponse';

export class OrderApi {
  static readonly KEY = 'order';

  static async confirm(receipt_id: number): Promise<any> {
    const { data } = await axios.post('/api/v1/order', undefined, {
      params: {
        receipt_id,
      },
    });

    return data;
  }

  static async getActive(): Promise<OrderDetails[]> {
    const { data } = await axios.get<CollectionApiResponse>('/api/v1/client/order/active');

    return plainToInstance(OrderDetails, data.items);
  }

  static async getAll(isClient: boolean, search?: string): Promise<Order[]> {
    const { data } = await axios.get<CollectionApiResponse>(isClient ? '/api/v1/client/order' : '/api/v1/order', {
      params: {
        search,
      },
    });

    return plainToInstance(Order, data.items);
  }

  static async getById(id: number, isClient = false): Promise<OrderDetails> {
    const { data } = await axios.get<ApiResponse>(isClient ? `/api/v1/client/order/${id}` : `/api/v1/order/${id}`);

    return plainToInstance(OrderDetails, data.data);
  }

  static async getNotPaid(): Promise<OrderDetails[]> {
    const { data } = await axios.get<CollectionApiResponse>('/api/v1/client/order/not_paid');

    return plainToInstance(OrderDetails, data.items);
  }

  static async getHistoryByPatientId(id: string): Promise<Order[]> {
    const { data } = await axios.get<CollectionApiResponse>(`/api/v1/order/history/${id}`);

    return plainToInstance(Order, data.items);
  }
}
