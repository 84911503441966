import type { EnumEntity } from './EnumEntity';
import { Payment } from './Payment';

export class PaymentDetails extends Payment {
  readonly peyment_type!: EnumEntity;
  readonly account!: {
    id: 0;
    name: string;
    masked_number: string;
  };
  readonly account_type!: EnumEntity;
}
