export class Doctor {
  readonly id!: number;
  readonly midname!: string;
  readonly name!: string;
  readonly surname!: string;

  get fullName(): string {
    return [this.name, this.midname, this.surname].filter(Boolean).join(' ');
  }
}
