import { useQuery } from '@tanstack/react-query';
import format from 'date-fns/format';
import { FC, useState } from 'react';
import { OrderApi } from '../../api';
import { Order } from '../../model';
import { formatMoney } from '../../utils';
import { ClientOrderCard } from '../molecules';
import { OrderDetailsModal } from './OrderDetailsModal';

export const ClientOrders: FC = () => {
  const [orderId, setOrderId] = useState<number>();
  const { data: allOrders = [] } = useQuery({
    queryKey: [OrderApi.KEY, 'getAll', true],
    queryFn: () => OrderApi.getAll(true),
  });
  const { data: activeOrders = [] } = useQuery({
    queryKey: [OrderApi.KEY, 'getActive'],
    queryFn: OrderApi.getActive,
  });
  const { data: notPaidOrders = [] } = useQuery({
    queryKey: [OrderApi.KEY, 'getNotPaid'],
    queryFn: OrderApi.getNotPaid,
  });

  return (
    <>
      <OrderDetailsModal
        onClose={() => {
          setOrderId(undefined);
        }}
        orderId={orderId}
      />

      <div className="mb-[36px] grid grid-cols-2 gap-[36px]">
        {notPaidOrders.map((order) => (
          <ClientOrderCard key={order.id} order={order} />
        ))}
        {activeOrders.map((order) => (
          <ClientOrderCard active key={order.id} order={order} />
        ))}
      </div>

      <div className="bg-white rounded-lg overflow-hidden text-brand-1">
        <header className="mb-2 py-[16px] px-[24px] bg-brand-6 rounded-t-lg">
          <h2 className="text-[20px] leading-normal font-bold">История заказов</h2>
        </header>
        <table className="table">
          <thead>
            <tr>
              <th className="w-[24px] border-none">&nbsp;</th>
              <th className="text-left">Номер заказа</th>
              <th className="text-left">Доставлен</th>
              <th className="text-left">Лекарства</th>
              <th className="text-left">Цена</th>
              <th className="text-left">Статус</th>
              <th>&nbsp;</th>
              <th className="w-[24px] border-none">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {allOrders.map((order) => (
              <OrderRow order={order} onClick={() => setOrderId(order.id)} key={order.id} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const OrderRow: FC<{ order: Order; onClick(): void }> = ({ order, onClick }) => {
  return (
    <tr>
      <td className="border-none" />
      <td>
        <span className="border-b border-brand-1">№{order.id}</span>
      </td>
      <td>{format(order.status_date, 'dd.MM.yyyy')}</td>
      <td>
        <span className="border-b border-brand-1">{order.medicine_count}</span>
      </td>
      <td>{formatMoney(order.price)}</td>
      <td>{order.status.value}</td>
      <td>
        <button className="text-brand-5 border-b border-brand-5" onClick={onClick} type="button">
          Детали
        </button>
      </td>
      <td className="border-none" />
    </tr>
  );
};
