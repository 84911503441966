import type { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePath } from '../../config';
import { useAuthContext } from '../../hooks';

interface Props {
  redirectPath?: RoutePath;
}

export const PrivateRoute: FC<PropsWithChildren<Props>> = ({ children, redirectPath = RoutePath.ClientLogin }) => {
  const { isAuthorized } = useAuthContext();

  if (!isAuthorized) {
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};
