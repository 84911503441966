import { useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { OrderApi } from '../api';
import { PrivateRoute } from '../components';
import { RoutePath } from '../config';
import { usePagination } from '../hooks';
import { DoctorInnerPageLayout } from '../layouts';
import { formatDateRu, formatMoney } from '../utils';
import classNames from 'classnames';

export const DoctorOrderHistoryPage: FC = () => {
  const [searchText, setSearchText] = useState('');
  const { data: allOrders = [] } = useQuery({
    keepPreviousData: true,
    queryFn: () => OrderApi.getAll(false, searchText),
    queryKey: [OrderApi.KEY, 'getAll', false, searchText],
  });
  const pagination = usePagination(allOrders.length);

  return (
    <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
      <DoctorInnerPageLayout>
        <div className="bg-brand-6 rounded-lg">
          <header className="p-[24px]">
            <input
              className="input input--search"
              onChange={(event) => setSearchText(event.target.value)}
              placeholder="Введите ФИО клиента или № заказа"
              type="text"
            />
          </header>
          <div className="px-[24px] pt-[24px] pb-[12px] bg-white rounded-lg max-h-[calc(100vh_-_202px)] overflow-auto">
            <table
              className={classNames('table', {
                long: pagination.isActive,
              })}
            >
              <thead>
                <tr>
                  <th className="text-left">Номер заказа</th>
                  <th className="text-left">Доставлен</th>
                  <th className="text-left">Лекарства</th>
                  <th className="text-left">Цена</th>
                  <th className="text-left">Статус</th>
                  <th className="text-left">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {allOrders.slice(pagination.begin, pagination.end).map((order) => {
                  const pathToOrderItem = generatePath(RoutePath.DoctorOrderHistoryItem, {
                    orderId: order.id.toString(),
                  });

                  return (
                    <tr key={order.id}>
                      <td>
                        <Link className="underline" to={pathToOrderItem}>
                          №{order.id}
                        </Link>
                      </td>
                      <td>{formatDateRu(order.status_date, 'dd.MM.yyyy')}</td>
                      <td className="underline">{order.medicine_count}</td>
                      <td>{formatMoney(order.price)}</td>
                      <td>{order.status.value}</td>
                      <td className="underline text-brand-5">
                        <Link to={pathToOrderItem}>Детали</Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {pagination.isActive && (
                <tfoot>
                  <tr>
                    <td colSpan={6}>
                      <button className="underline text-brand-5 font-[16px]" onClick={pagination.forward} type="button">
                        Смотреть больше
                      </button>
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      </DoctorInnerPageLayout>
    </PrivateRoute>
  );
};
