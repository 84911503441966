import { useQuery } from '@tanstack/react-query';
import plural from 'plural-ru';
import { FC } from 'react';
import { OrderApi } from '../../api';
import { formatDateRu, formatMoney } from '../../utils';
import { OrderItemRow } from '../molecules';

interface Props {
  orderId: number;
}

export const DoctorOrderDetails: FC<Props> = ({ orderId }) => {
  const { data: orderDetails } = useQuery({
    enabled: !!orderId,
    queryFn: () => OrderApi.getById(Number(orderId)),
    queryKey: [OrderApi.KEY, 'getById', orderId],
  });

  if (!orderDetails) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg">
      <header className="p-[24px]">
        <span className="font-bold text-[24px] leading-[36px]">
          №{orderId}
          <br />
          {orderDetails.status.value} {formatDateRu(orderDetails.status_date, 'dd MMMM, yyyy')}
        </span>
        <span className="mt-[12px] block text-brand-1/50">
          Статус заказа:&nbsp;<span className="text-brand-5 font-bold">{orderDetails.status.value}</span>
        </span>
      </header>

      <div className="bg-brand-1 rounded-lg">
        <header className="flex items-center justify-between py-[16px] px-[24px] text-white">
          <span>Лекарства</span>
          <span>
            Сформировал(а) заказ:&nbsp;
            <span className="text-brand-5 underline">{orderDetails.doctor.fullName}</span>
          </span>
        </header>

        <div className="px-[24px] pt-[24px] pb-[12px] bg-white rounded-lg">
          <table className="table">
            <tbody>
              {orderDetails.items.map((item, index) => (
                <OrderItemRow key={item.id} item={item} index={index} />
              ))}
              <tr>
                <td />
                <td>
                  Итого за {orderDetails.medicine_count}{' '}
                  {plural(orderDetails.medicine_count, 'лекарство', 'лекарства', 'лекарств')}
                </td>
                <td className="text-right text-[20px]">{formatMoney(orderDetails.price)}</td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
