import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import { ApiResponse, Payment, PaymentDetails } from '../model';
import { CollectionApiResponse } from '../model/CollectionApiResponse';

export class PaymentApi {
  static readonly KEY = 'payment';

  static async getAll(): Promise<Payment[]> {
    const { data } = await axios.get<CollectionApiResponse>('/api/v1/client/payment');

    return plainToInstance(Payment, data.items);
  }

  static async getById(id: number): Promise<PaymentDetails> {
    const { data } = await axios.get<ApiResponse>(`/api/v1/client/payment/${id}`);

    return plainToInstance(PaymentDetails, data.data);
  }
}
