import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '../../api';
import { RoutePath } from '../../config';
import { AuthContext } from '../../model';

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isClient, setIsClient] = useState(true);
  const [authorized, setAuthorized] = useState<boolean>(Boolean(Cookies.get('jwt')));

  const signIn = useCallback((isClient: boolean) => {
    setAuthorized(true);
    setIsClient(isClient);
  }, []);

  const signOut = useCallback(async () => {
    await AuthApi.logOut();
    setAuthorized(false);
    navigate(isClient ? RoutePath.ClientLogin : RoutePath.DoctorLogin);
    queryClient.clear();
  }, [navigate, isClient, queryClient]);

  return <AuthContext.Provider value={{ isAuthorized: authorized, signIn, signOut }}>{children}</AuthContext.Provider>;
};
