import formatDate from 'date-fns/format';
import formatDuration from 'date-fns/formatDuration';
import ruLocale from 'date-fns/locale/ru';
import plural from 'plural-ru';
//  @ts-ignore
window.plural = plural
export function noop() {}

export function normalizePhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/\s|\+/g, '');
}

export function formatMedicinePlural(amount: number): string {
  return plural(amount, 'таблетка', 'таблетки', 'таблеток');
}

export function formatMoney(amount: number): string {
  return amount.toLocaleString('ru', {
    style: 'currency',
    currency: 'rub',
  });
}

export function formatDateRu(...[date, format, options]: Parameters<typeof formatDate>): string {
  return formatDate(date, format, {
    ...options,
    locale: ruLocale,
  });
}

export function formatDurationRu(...[duration, options]: Parameters<typeof formatDuration>): string {
  return formatDuration(duration, {
    ...options,
    locale: ruLocale,
  });
}

export function sum(arr: number[]): number {
  return arr.reduce((acc, curr) => acc + curr, 0);
}

export function capitalize(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
