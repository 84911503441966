import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { DoctorOrderDetails, PrivateRoute } from '../components';
import { RoutePath } from '../config';
import { DoctorInnerPageLayout } from '../layouts';

export const DoctorOrderHistoryItemPage: FC = () => {
  const { orderId } = useParams<{ orderId: string }>();

  if (!orderId) {
    return (
      <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
        <DoctorInnerPageLayout />
      </PrivateRoute>
    );
  }

  return (
    <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
      <DoctorInnerPageLayout>
        <div className="bg-brand-6 rounded-lg">
          <header className="p-[24px]">
            <Link className="flex items-center gap-[6px] text-[20px] font-bold" to={RoutePath.DoctorOrderHistory}>
              <BackIcon />
              Вернуться назад
            </Link>
          </header>

          <DoctorOrderDetails orderId={Number(orderId)} />
        </div>
      </DoctorInnerPageLayout>
    </PrivateRoute>
  );
};
