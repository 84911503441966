import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import { Logo } from '../components';
import styles from './ClientAuthLayout.module.css';

export const ClientAuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={classNames(styles.container, 'min-h-screen p-2 grid md:grid-cols-2')}>
      <div />
      <div className="grid grid-rows-[1fr_4fr_1fr] min-h-[calc(100vh-40px)] p-[36px] bg-white rounded-lg">
        <header className="flex justify-end">
          <Logo />
        </header>
        {children}
        <footer className="flex justify-center items-end text-[12px] text-black/50">
          ©&nbsp;{new Date().getFullYear()}&nbsp;Pax. Все права защищены.
        </footer>
      </div>
    </div>
  );
};
