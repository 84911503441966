import { useFormContext } from 'react-hook-form';

export interface ClientLoginFormFields {
  code: string;
  phoneNumber: string;
}

export function useClientLoginForm() {
  return useFormContext<ClientLoginFormFields>();
}
