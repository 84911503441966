import { Transition, Dialog } from '@headlessui/react';
import { FC, Fragment, PropsWithChildren } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

interface Props extends PropsWithChildren {
  isOpen: boolean;
  onClose(): void;
}

export const Modal: FC<Props> = ({ children, isOpen, onClose }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose} unmount={false}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-brand-1/70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-2 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[800px] transform overflow-hidden rounded-xl bg-white text-left align-middle transition-all">
                <button
                  className="absolute top-[12px] right-[12px] z-10 flex justify-center items-center h-[24px] w-[24px] transition-all"
                  onClick={onClose}
                  type="button"
                >
                  <CloseIcon />
                </button>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
