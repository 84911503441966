import { Transform, Type } from 'class-transformer';
import parse from 'date-fns/parse';
import { Doctor } from './Doctor';
import { ReceiptMedicine } from './ReceiptMedicine';

export class Receipt {
  @Transform(({ value }) => parse(value, 'dd.MM.yyyy HH:mm:ss', new Date()), { toClassOnly: true })
  readonly start_date!: Date;

  readonly description!: string;
  readonly order_id!: number;
  readonly hospital_id!: number;

  @Type(() => Doctor)
  readonly doctor!: Doctor;
  readonly receipt_medicine!: ReceiptMedicine[];
}
