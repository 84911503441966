import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { RouteProps, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CommonApi, PatientDTO, PeopleApi } from '../api';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { PrivateRoute, Select } from '../components';
import { RoutePath } from '../config';
import { DoctorInnerPageLayout } from '../layouts';
import { normalizePhoneNumber } from '../utils';
import moment from "moment";

export const DoctorCreatePatientPage: FC<RouteProps> = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<PatientDTO>();
  const { data: genderList = [] } = useQuery({
    queryKey: [CommonApi.KEY, 'getReference', 107],
    queryFn: () => CommonApi.getReference(107),
  });
  const { mutate: createPatient } = useMutation({
    mutationFn: PeopleApi.createPatient,
    onSuccess() {
      queryClient.refetchQueries([PeopleApi.KEY, 'getPatients']);
      navigate(RoutePath.DoctorPatients);
    },
    onError(error) {
      console.error(error);
      toast.error('Ошибка! Проверьте код');
    },
  });
  const { mutate: sendCodeToPatient } = useMutation({
    mutationFn: PeopleApi.sendCodeToPatient,
    onSuccess(response) {
      clearErrors();
      setIsCodeSent(true);
    },
  });

  const onSubmit: SubmitHandler<PatientDTO> = useCallback(
    async (data) => {
      if (isCodeSent) {
        createPatient({
          ...data,
          username: normalizePhoneNumber(data.username),
          birthdate: moment(data.birthdate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        });
      } else {
        sendCodeToPatient(normalizePhoneNumber(data.username));
      }
    },
    [createPatient, isCodeSent, sendCodeToPatient],
  );

  return (
    <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
      <DoctorInnerPageLayout>
        <div className="grid grid-cols-2">
          <div className="bg-brand-6 rounded-lg">
            <header className="p-2">
              <button
                className="flex items-center gap-[6px] text-[20px] font-bold"
                onClick={() => navigate(-1)}
                type="button"
              >
                <BackIcon />
                Вернуться назад
              </button>
            </header>

            <form className="grid gap-1 p-2" onSubmit={handleSubmit(onSubmit)}>
              <input
                  {...register('surname', {
                    required: true,
                  })}
                  className={classNames('input', errors.surname && 'input--invalid')}
                  name="surname"
                  placeholder="Фамилия"
                  type="text"
              />
              <input
                  {...register('name', {
                    required: true,
                  })}
                  className={classNames('input', errors.name && 'input--invalid')}
                  name="name"
                  placeholder="Имя"
                  type="text"
              />
              <input
                  {...register('midname', {
                    required: true,
                  })}
                  className={classNames('input', errors.midname && 'input--invalid')}
                  name="midname"
                  placeholder="Отчество"
                  type="text"
              />
              <Controller
                  control={control}
                  name="gender_id"
                  rules={{
                    required: true,
                  }}
                  render={({field, fieldState}) => {
                    return (
                        <Select
                            error={fieldState.error}
                            onChange={field.onChange}
                            options={genderList.map((gender) => ({
                              label: gender.value,
                              value: gender.id,
                            }))}
                            placeholder="Пол"
                            value={field.value}
                        />
                    );
                  }}
              />
              <Controller
                  control={control}
                  name="username"
                  rules={{
                    required: true,
                  }}
                  render={({field, fieldState}) => {
                    return (
                        <InputMask
                            {...field}
                            alwaysShowMask
                            className={classNames('input', fieldState.invalid && 'input--invalid')}
                            mask="+7 999 999 99 99"
                            maskPlaceholder={null}
                            type="tel"
                            value={field.value ?? ''}
                        />
                    );
                  }}
              />

              <Controller
                  control={control}
                  name="birthdate"
                  rules={{
                    required: true,
                  }}
                  render={({field, fieldState}) => {
                    return (
                        <InputMask
                            {...field}
                            alwaysShowMask
                            className={classNames('input', fieldState.invalid && 'input--invalid')}
                            mask="99-99-9999"
                            maskPlaceholder={null}
                            placeholder="Дата Рождения (ДД-ММ-ГГГГ)"
                            type="tel"
                            value={field.value ?? ''}
                        />
                    );
                  }}
              />
              {isCodeSent && (
                  <Controller
                      control={control}
                      name="code"
                      rules={{
                        required: true,
                      }}
                      render={({field, fieldState}) => {
                        return (
                            <InputMask
                                {...field}
                                alwaysShowMask
                                className={classNames('input', fieldState.invalid && 'input--invalid')}
                                mask="9999"
                                maskPlaceholder={null}
                                placeholder="Код из SMS"
                                type="tel"
                                value={field.value ?? ''}
                            />
                        );
                      }}
                  />
              )}
              <button className="mt-2 button button--block button--primary" type="submit">
                {isCodeSent ? 'Добавить клиента' : 'Отправить код'}
              </button>
            </form>
          </div>
        </div>
      </DoctorInnerPageLayout>
    </PrivateRoute>
  );
};
