import type { Address } from './Address';
import type { Card } from './Card';

export class User {
  readonly address: Address[] = [];
  readonly cards: Card[] = [];
  readonly midname!: string;
  readonly name!: string;
  readonly people_main_id!: number;
  readonly surname!: string;
  readonly user_id!: number;

  get fullName(): string {
    return [this.name, this.midname, this.surname].filter(Boolean).join(' ');
  }
}
