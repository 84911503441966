import classNames from 'classnames';
import { ChangeEvent, ClipboardEvent, FC, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

const CODE_LENGTH = 4;
const cells = Array.from(Array(CODE_LENGTH).keys());

export const CodeField: FC = () => {
  const {
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const cellRefs = useRef<Array<HTMLInputElement | null>>([]);
  const code = watch('code');
  const values = code.split('');

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const data = e.clipboardData.getData('text');
    clearErrors('code');
    setValue('code', data);
    const activeIndex = data.length >= CODE_LENGTH ? CODE_LENGTH - 1 : data.length;
    window.setTimeout(() => cellRefs.current[activeIndex]?.focus());
  };

  const handleChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    clearErrors('code');
    const { value } = e.target;

    if (value.length === 0 && index > 0) {
      cellRefs.current[index - 1]?.focus();
    }

    if (value.length > 0 && index < CODE_LENGTH) {
      cellRefs.current[index + 1]?.focus();
    }

    values[index] = value;
    setValue('code', values.join(''));
  };

  const invalid = Boolean(errors['code']);

  return (
    <div className="mb-6 flex justify-center gap-[16px]">
      {cells.map((index) => (
        <InputMask
          key={index}
          mask="9"
          maskPlaceholder={null}
          onPaste={index === 0 ? handlePaste : undefined}
          onChange={handleChange(index)}
          value={values[index] ?? ''}
        >
          <input
            ref={(el) => (cellRefs.current[index] = el)}
            className={classNames('input flex-[0_0_60px] input--code', {
              'input--invalid': invalid,
            })}
            type="tel"
          />
        </InputMask>
      ))}
    </div>
  );
};
