import { useCallback, useState, useMemo } from 'react';

export interface PaginationProps {
  begin: number;
  end: number;
  isActive: boolean;
  page: number;
  perPage: number;
  totalPages: number;

  back(): void;
  forward(): void;
  setPage(page: number): void;
}

export function usePagination(totalItems: number, perPage = 7): PaginationProps {
  const [page, setPage] = useState(0);

  const totalPages = useMemo(() => (totalItems < perPage ? 1 : Math.ceil(totalItems / perPage)), [perPage, totalItems]);
  const end = useMemo(() => page * perPage + perPage, [page, perPage]);
  const isActive = useMemo(() => totalPages > 0 && page !== totalPages - 1, [page, totalPages]);

  const back = useCallback(() => setPage((current) => (current > 0 ? current - 1 : current)), [setPage]);
  const forward = useCallback(
    () => setPage((current) => (current < totalPages - 1 ? current + 1 : current)),
    [setPage, totalPages],
  );

  return {
    begin: 0,
    end,
    isActive,
    page,
    perPage: perPage,
    totalPages,

    back,
    forward,
    setPage,
  };
}
