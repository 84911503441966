import { Transform } from 'class-transformer';
import parse from 'date-fns/parse';
import type { EnumEntity } from './EnumEntity';

export class Payment {
  readonly id!: number;

  @Transform(({ value }) => parse(value, 'dd.MM.yyyy HH:mm:ss', new Date()), { toClassOnly: true })
  readonly process_date!: Date;

  readonly ispaid!: boolean;
  readonly amount!: number;
  readonly order_id!: number;
  readonly status!: EnumEntity;
}
