import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import { FieldError } from 'react-hook-form';

interface Props {
  className?: string;
  error?: FieldError;
  onChange(newValue: number): void;
  options: SelectOption[];
  placeholder?: string;
  value: number;
}

export interface SelectOption {
  label: string;
  value: number;
}

export const Select: FC<Props> = ({ className, error, onChange, options, placeholder, value }) => {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Listbox value={value} onChange={onChange}>
      <div className={classNames('relative', className)}>
        <Listbox.Button
          className={classNames('input input--block bg-white text-left', {
            'input--invalid': Boolean(error),
          })}
        >
          <span
            className={classNames('block truncate', {
              'text-brand-1/50': !selectedOption,
            })}
          >
            {selectedOption ? selectedOption.label : placeholder}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ArrowDownIcon aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-[300px] w-full overflow-auto rounded-md bg-white shadow-lg border border-brand-2">
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                className="relative cursor-pointer select-none p-2 hover:bg-brand-6"
                value={option.value}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={classNames('block truncate', {
                        'font-bold': selected,
                      })}
                    >
                      {option.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
